import { useRoutes, useLocation } from 'react-router-dom'
import {pcRoutes, mobileRoutes} from '@/router/index.js';
import { useContext, useState, useEffect } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import {Row, Col} from 'antd'

import Header from "./components/Header"
import Footer from "./components/Footer"
import MobileHeader from './components/MobileHeader'
import MobileFooter from './components/MobileFooter'
import AffixButtonGroup from './components/AffixButtonGroup';
import mobileFooterBarIcon1 from '@/imgs/new/mobileFooterBarIcon1.svg'
import mobileFooterBarIcon2 from '@/imgs/new/mobileFooterBarIcon2.svg'
import './index.scss'
import {GlobalContext} from '@/App.js'
const navigator = window.navigator.userAgent
const isMobile = navigator.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)

const Layout = () => {
    const {setHeaderTheme} = useContext(GlobalContext)
    const location = useLocation()
    
    const routes = isMobile ? mobileRoutes : pcRoutes
    
    const element = useRoutes(routes)

    const [isIframe, setIsIframe] = useState(false)

    useEffect(() => {
        if(location.pathname !== '/') {
            setHeaderTheme('dark')
        }
    }, [location])

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    const handleCloseIframe = () => {
        setIsIframe(false)
    }

    const handleOpenIframe = () => {
        setIsIframe(true)
    }

    const handleOpenFeiShu = () => {
        window.open('https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc', '_blank')
    }

    const handleOpenAgent = () => {
        window.open('https://uat.agentspro.cn/agent/outlink?hash=HD3YXW2NCU', '_self')
    }

    return(
        <div className='layout'>
            {
                isMobile ? <MobileHeader></MobileHeader> : <Header></Header>
            }
            <div className="main">
                { element }
            </div>
            {
                !isMobile && <AffixButtonGroup scrollToBottom={scrollToBottom} handleOpenIframe={handleOpenIframe}></AffixButtonGroup>
            }
            
            {
                isMobile ? <MobileFooter></MobileFooter> : <Footer></Footer>
            }
            {
                isMobile &&
                <Row className='mobile-footer-bar'>
                    <Col onClick={() => handleOpenFeiShu()} span={11} className='mobile-footer-bar-button'>
                        <img className='button-icon' src={mobileFooterBarIcon2} alt="" />
                        <div className='button-label'>联系我们</div>
                    </Col>
                    <Col onClick={() => handleOpenAgent()} span={11} className='mobile-footer-bar-button' style={{backgroundColor: 'rgba(64, 96, 255, 1)', color: '#fff'}}>
                        <img className='button-icon' src={mobileFooterBarIcon1} alt="" />
                        <div className='button-label'>在线咨询</div>
                    </Col>
                </Row>
            }
            {
                isIframe && 
                <div className='iframe-box'>
                    <div className='iframe-box-title'>
                        未来式智能小助手
                        <span className='close-btn' onClick={() => handleCloseIframe()}><CloseOutlined /></span>
                    </div>
                    <iframe
                        title="Embedded Content"
                        width="600"
                        height="560"
                        src="https://uat.agentspro.cn/agent/outlink?hash=HD3YXW2NCU"
                    ></iframe>
                </div>
            }
            
        </div>
    )
}

export default Layout