import './index.scss'
import messageDotsSquare from '@/imgs/new/messageDotsSquare.svg'
import AffixButtonIcon3 from '@/imgs/new/AffixButtonIcon3.svg'

import { Divider } from 'antd';
const AffixButtonGroup = ({scrollToBottom, handleOpenIframe}) => {
    const handleAffixButtonClick = () => {
        scrollToBottom()
    }
    return(
        <div className='affix-button-group'>
            <div className='button-box' onClick={() => handleOpenIframe()}>
                <img className='button-icon' src={messageDotsSquare} alt="" />
                <div className='button-label'>在线咨询</div>
            </div>
            <Divider style={{margin: '0'}}></Divider>
            <div className='button-box' onClick={() => handleAffixButtonClick('')}>
                <img className='button-icon' src={AffixButtonIcon3} alt="" />
                <div className='button-label'>商务合作</div>
            </div>
        </div>
    )
}

export default AffixButtonGroup