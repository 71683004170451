import { useState, useEffect, useContext } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import blackLogo from '@/imgs/new/logo.svg'
import whiteLogo from '@/imgs/new/whiteLogo.svg'
import {GlobalContext} from '@/App.js'

import { Divider } from 'antd';

import './index.scss'
import logo from '@/imgs/new/logo.svg'
import HeaderMenuIcon from '@/imgs/new/HeaderMenuIcon.svg'
import WhiteHeaderMenuIcon from '@/imgs/new/WhiteHeaderMenuIcon.svg'
import Close from '@/imgs/new/Close.svg'
const linkList = [
    {
        label: '首页',
        link: '/',
    },
    {
        label: '产品介绍',
        link: '/CoreTechnology',
    },
    {
        label: '行业方案',
        link: '/CoreProduct',
    },
    {
        label: '关于我们',
        link: '/AboutUs',
    },
    {
        label: '加入我们',
        link: '/ContactUs',
    }
]

const Header = () => {
    const [isMenu, setIsMenu] = useState(false)
    const routerLocation = useLocation()
    const {headerTheme} = useContext(GlobalContext)
    useEffect(() => {
        linkList.map(item => {
            if(item.link === routerLocation.pathname && item.link !== '/') {
                document.title = `${item.label} | Autoagents`
            }
        })
    }, []);


    const handleNavLinkClick = (label) => {
        window.scrollTo(0, 0)
        if(label === '首页') {
            document.title = `北京未来式智能科技有限公司 | AutoAgents.ai | AI Agents 大语言模型场景化落地产品服务提供商`
        } else {
            document.title = `${label} | Autoagents`
        }
        setIsMenu(false)
    }


    const handleOpenMenuClick = () => {
        setIsMenu(true)
    }

    const handleCloseMenuClick = () => {
        setIsMenu(false)
    }

    return(
        <>
            <div className='mobile-page-header' style={headerTheme==='dark' ? {backdropFilter:'blur(15px)',WebkitBackdropFilter: 'blur(15px)',backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backdropFilter:'blur(0)',WebkitBackdropFilter: 'blur(0)',backgroundColor: 'rgba(255, 255, 255, 0)'}}>
                <div className='logo-box'>
                    <img className='logo' src={headerTheme==='dark' ? blackLogo : !isMenu ? whiteLogo : blackLogo} alt="logo" />
                </div>
                <div className='menu-icon-box'>
                    {
                        isMenu ? <img onClick={handleCloseMenuClick} src={Close} alt="" /> : <img onClick={handleOpenMenuClick} src={headerTheme==='dark' ? HeaderMenuIcon: WhiteHeaderMenuIcon} alt="" />
                    }
                </div>
            </div>
            {
                isMenu && 
                <div className="page-header-menu">
                    { 
                            linkList.map(item => {
                                return (
                                    <>
                                        <NavLink 
                                            key={item.label} 
                                            className={ ({ isActive, isPending }) => isPending ? "pending" : isActive ? "active title-link" : "title-link" } 
                                            to={item.link}
                                            onClick={() => handleNavLinkClick(item.label)}
                                        >
                                            {item.label}
                                        </NavLink>
                                        <Divider className='diveder-line'></Divider>
                                    </>
                                )
                            })
                    }
                </div>
            }
            
        </>
        
    )
}

export default Header