import './index.scss'
import Avater1 from '@/imgs/new/avater1.svg'
import Avater2 from '@/imgs/new/avater2.svg'
import Avater4 from '@/imgs/new/avater4.svg'

import { Divider, Modal, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Mousewheel } from 'swiper/modules';
import { useState, useEffect } from 'react';
import logo1 from '@/imgs/logo/logo1.png'
import logo18 from '@/imgs/logo/logo18.png'
import logo2 from '@/imgs/logo/logo2.png'
import logo3 from '@/imgs/logo/logo3.png'
import logo4 from '@/imgs/logo/logo4.png'
import logo6 from '@/imgs/logo/logo6.png'
import logo7 from '@/imgs/logo/logo7.png'
import logo5 from '@/imgs/logo/logo5.png'
import logo9 from '@/imgs/logo/logo9.png'
import logo10 from '@/imgs/logo/logo10.png'
import logo11 from '@/imgs/logo/logo11.png'
import logo8 from '@/imgs/logo/logo8.png'
import logo13 from '@/imgs/logo/logo13.png'
import logo14 from '@/imgs/logo/logo14.png'
import logo15 from '@/imgs/logo/logo15.png'
import logo16 from '@/imgs/logo/logo16.png'
const AboutUs = () => {
    const [swiperCount, setSwiperCount] = useState(4)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleCloseModal = () => {
        setIsModalOpen(false)
    }
    const handleOpenModal = () => {
        setIsModalOpen(true)
    }
    useEffect(() => {
        const handleResize = () => {
          if(window.innerWidth >= 1500) {
            setSwiperCount(4)
          } else if (window.innerWidth >= 1140) {
            setSwiperCount(3)
          } else {
            setSwiperCount(2)
          }
        };
        handleResize()
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <>
            <div className='about-us'>
                <div className="about-header">
                    <img className='about-header-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/AboutUsHeader.svg' alt="" />
                    <div className='about-header-content'>
                        <p className='title'>未来式智能 | AutoAgents.ai</p>
                        <p className='desc'>企业级大模型场景化应用提供商</p>
                        {/* <div className='button' onClick={handleOpenModal}>
                            <CaretRightOutlined style={{position: 'relative', left: '3px'}} />
                        </div> */}
                    </div>
                </div>
                <div className='about-us-introduce'>
                    <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/pcAboutUsIntroduceBg.svg' alt="" />
                    <div className='about-us-introduce-left-font-box'>
                        <div className='left-font-item'>
                            <div className='label'>愿景</div>
                            <div className='desc'>让全球10亿知识工作者10倍效工作</div>
                        </div>
                        <div className='left-font-item m-tb-62'>
                            <div className='label'>使命</div>
                            <div className='desc'>致力成为全球领先的Agent创新企业 使AI Agent触手可及</div>
                        </div>
                        <div className='left-font-item'>
                            <div className='label'>价值观</div>
                            <div className='desc'>创新驱动、客户价值、合作共生</div>
                        </div>
                    </div>
                    <div className='about-us-introduce-right-font-box'>
                        <p>未来式智能是一家创新工场投资的人工智能技术公司，是国内首批大语言模型技术落地和应用场景公司，致力于解决大语言模型到场景化落地应用“最后一公里”问题；旨在为电力能源、金融保险、泛互联网、公共服务、医疗健康等头部客户提供Agents应用产品及服务，与企业一起构建人与AI混合工作的知识工作流水线。</p>
                        <p style={{marginBottom: '16px', marginTop: '16px'}}>
                            我们基于自研企业级应用构建平台，致力于为全球多个国家和地区的企业提供自主智能体（AI Agents）产品及服务，打破传统固化业务流，帮助企业构建高度智能化的底层通用业务流，<span style={{fontWeight: 'bold'}}>打造人机共创的工作新范式，引领企业业务流程智能化升级，让全球10亿知识工作者10倍效工作。</span>
                        </p>
                        <p>
                            我们已与阿里云、腾讯云、华为、火山引擎、智谱AI、零一万物、昆仑万维-天工等企业以及知名院校达成深度合作，共同利用大语言模型为企业提供创新动力；成功落地航班预定、合同审核、客户运营、电网安全调度助手、内容生成流水线、海外新媒体运营等应用场景，赋能企业AI新范式。
                        </p>
                    </div>
                </div>
                <div className='enterprise-honor'>
                    <p className='title'>企业荣誉</p>
                    <p className='desc'>我们凭借技术与产品的领先优势，加速知识产权的积累，目前已获得多项专利与软著认证，推动整个行业的技术进步和应用创新。</p>
                </div>
                <Swiper
                    modules={[Autoplay, Mousewheel]}
                    slidesPerView={swiperCount}
                    spaceBetween={10}
                    loop={true} // 开启循环滚动
                    autoplay={{
                        delay: 3000, // 自动播放间隔时间，单位为毫秒
                        disableOnInteraction: true, // 用户操作后是否禁用自动播放
                        pauseOnMouseEnter: true
                    }}
                >
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification1.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>资质认证·2024年</p>
                                <p className='title'>质量管理体系认证证书</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification2.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>资质认证·2024年</p>
                                <p className='title'>信息技术服务管理体系认证证书</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification3.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>资质认证·2024年</p>
                                <p className='title'>信息安全管理体系认证证书</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification4.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>资质认证·2024年</p>
                                <p className='title'>中关村高新技术企业</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification5.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>发明专利·2023年</p>
                                <p className='title'>已获得多项发明专利</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box'>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/Certification6.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='time'>资质认证·2023年</p>
                                <p className='title'>已获得多项软件著作</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div style={{padding: '96px'}}>
                    <Divider />
                </div>
                {/* <div className='core-team'>
                    <p className='title'>核心团队</p>
                    <div className='person'>
                        <div className='introduce'>
                            <div className='top'>
                                <span className='name'>杨劲松</span>
                                <span className='job-title'>创始人&CEO</span>
                            </div>
                            <div className='tag-box'>
                                <div className='tag'>
                                    前达摩院产品/商业化总监
                                </div>
                                <div className='tag'>
                                    字节飞书AI与大数据产品
                                </div>
                                <div className='tag'>
                                    北京大学元培实验班本硕、康奈尔MBA
                                </div>
                                <div className='tag'>
                                    亚马逊AWS aPaaS平台负责人、SAP高级架构师、埃森哲咨询经理
                                </div>
                            </div>
                            <p className='desc'>
                                主导推出人工智能平台阿里灵杰，大语言模型开放平台通义-Alicemind；管理产品两次获得世界人工智能大会 SAIL 奖；两次入选达摩院十大科技趋势；入选世界智能大会优秀案例，入选新华网《中国数字样板板》；负责产品总收入超过20亿。
                            </p>
                        </div>
                        <div className='avater m-l-60'>
                            <img src={Avater1} alt="" />
                        </div>
                    </div>
                    <div className='person'>
                        <div className='avater m-r-60'>
                            <img src={Avater2} alt="" />
                        </div>
                        <div className='introduce'>
                            <div className='top'>
                                <span className='name'>邹阳</span>
                                <span className='job-title'>联合创始人&COO</span>
                            </div>
                            <div className='tag-box'>
                                <div className='tag'>
                                    前达摩院商业化总监
                                </div>
                                <div className='tag'>
                                    搜狗语音交互技术中心总监
                                </div>
                                <div className='tag'>
                                    魅族AI Lab创始人
                                </div>
                                <div className='tag'>
                                    金山云前身金山快盘创始团队成员
                                </div>
                            </div>
                            <p className='desc'>
                                在达摩院负责自然语言与智能语音产品线，所管理的语音语义产品IDC中国AI云服务市场占有率多年第一；曾负责国内最大的机器翻译开放平台的建设，支持日均10亿+次调用；主导阿里云多个大型AI项目从拓展到交付，20年签约项目金额1.4亿。
                            </p>
                        </div>
                    </div>
                    <div className='person'>
                        <div className='introduce'>
                            <div className='top'>
                                <span className='name'>郝洋</span>
                                <span className='job-title'>技术负责人</span>
                            </div>
                            <div className='tag-box'>
                                <div className='tag'>
                                    前BodyPark技术负责人
                                </div>
                                <div className='tag'>
                                    北京航空航天大学计算机科学与技术本科
                                </div>
                                <div className='tag'>
                                    前商汤科技工程院架构师、出门问问中台技术负责人、华为高级研发工程师
                                </div>
                            </div>
                            <p className='desc'>
                                主导 BodyPark 团队从 0 到 1 搭建，上线基于 2D 视觉人体动作捕捉的 AI 在线真人私教课。该课程平台排课数和上课人数均位列线上私教领域第一。主导落地与北京市体育局社体中心、Under Armour 中国区 合作的AI 体测项目。
                            </p>
                        </div>
                        <div className='avater m-l-60'>
                            <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/avater3.svg' alt="" />
                        </div>
                    </div>
                    <div className='person'>
                        <div className='avater m-r-60'>
                            <img src={Avater4} alt="" />
                        </div>
                        <div className='introduce'>
                            <div className='top'>
                                <span className='name'>魏双华</span>
                                <span className='job-title'>产品负责人</span>
                            </div>
                            <div className='tag-box'>
                                <div className='tag'>
                                    Google七年产品/研发
                                </div>
                                <div className='tag'>
                                    美国伊利诺伊大学香槟分校计算机科学学士，纽约大学MBA
                                </div>
                                <div className='tag'>
                                    连续创业者
                                </div>
                            </div>
                            <p className='desc'>
                                曾创立过三家初创公司，涉猎社交、营销等方向；曾负责YouTube搜索/AI，谷歌电商AI项目，及谷歌大客户广告部门，并曾担任谷歌内部孵化器Area 120产品总监。
                            </p>
                        </div>
                    </div>
                </div> */}
                {/* <div style={{padding: '72px 120px'}}>
                    <Divider />
                </div> */}
                <Row className='technical-team-box'>
                    <Col span={8} className='technical-team'>
                        <p className='title'>核心团队</p>
                        <p className='desc'>目前团队研发人员占比80%以上。核心技术团队拥有多年人工智能领域从业经验，成员来自多家全球领先的人工智能和云计算公司，如阿里巴巴达摩院、腾讯、字节跳动、亚马逊AWS和谷歌等。 </p>
                        <p className='desc'>多年来深耕服务企业数字化、智能化领域，拥有丰富的一线工作经验、雄厚的技术研发实力和产品商业化能力，并在大语言模型、多模态模型、自演进智能体、智能决策等领域有深厚技术积累。</p>
                    </Col>
                    <Col span={16}>
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <img className='col-img-6' src={logo1} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo18} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo2} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo3} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo4} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo6} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo7} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo5} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo9} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo10} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo11} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo8} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo13} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo14} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo15} alt="" />
                            </Col>
                            <Col span={6}>
                                <img className='col-img-6' src={logo16} alt="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Modal 
                title={null} 
                footer={null} 
                maskClosable={false} 
                keyboard={false} 
                open={isModalOpen} 
                onCancel={handleCloseModal}
                width='50%'
                wrapClassName='about-us-modal-video'
            >
                <video controls style={{width: '100%'}}>
                    <source src="https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/company-video.mp4" type="video/mp4" />
                </video>
            </Modal>
        </>
        
    )
}

export default AboutUs