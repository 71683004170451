import { useState } from 'react'
import { Link } from 'react-scroll';
import './index.scss'
import { Row, Col, Affix, Divider } from 'antd';
import CoreProductHeader from '@/imgs/new/CoreProductHeader.svg'
import Correct from '@/imgs/new/correct.svg'
import ProductIcon1 from '@/imgs/new/ProductIcon1.svg'
import ProductIcon2 from '@/imgs/new/ProductIcon2.svg'
import ProductIcon3 from '@/imgs/new/ProductIcon3.svg'
import ProductIcon4 from '@/imgs/new/ProductIcon4.svg'
import ProductIcon5 from '@/imgs/new/ProductIcon5.svg'
import ArrowRightIcon from '@/imgs/new/ArrowRightIcon.svg'
import Simulation from '@/imgs/new/Simulation.svg'
import VaccineKnowledgeBase from '@/imgs/new/VaccineKnowledgeBase.svg'

const CoreProduct = () => {

    const [customerStoryList, setCustomerStoryList] = useState([
        {
            id: 1,
            label: '社媒运营',
            active: true,
            link: 'socialMediaOperation'
        },
        {
            id: 2,
            label: '游戏客服',
            active: false,
            link: 'gameCustomerService'
        },
        {
            id: 3,
            label: '合同审核',
            active: false,
            link: 'contractReview'
        },
        {
            id: 4,
            label: '仿真模拟',
            active: false,
            link: 'simulation'
        },
        {
            id: 5,
            label: '航班预订',
            active: false,
            link: 'flightReservation'
        },
        {
            id: 6,
            label: '疫苗知识库',
            active: false,
            link: 'vaccineKnowledgeBase'
        }
    ])

    const handleCustomerTabClick = (val) => {
        let list = JSON.parse(JSON.stringify(customerStoryList))
        list.map(item => {
            if(item.id === val.id) {
                item.active = true
            } else {
                item.active = false
            }
        })
        setCustomerStoryList(list)
    }

    const handleSetActive = (to) => {
        let list = JSON.parse(JSON.stringify(customerStoryList))
        list.map(item => {
            if(item.link === to) {
                item.active = true
            } else {
                item.active = false
            }
        })
        setCustomerStoryList(list)
      };
    return (
        <div className='core-product'>
            <div className="core-header">
                <img className='core-header-img' src={CoreProductHeader} alt="" />
                <div className='core-header-content'>
                    <p className='title'>行业方案</p>
                    <p className='desc'>我们是一家企业级技术构建服务商，旨在为电力能源、金融保险、泛互联网、公共服务、医疗健康等头部客户提供全方位Agent场景解决方案及服务。</p>
                </div>
            </div>
            
            <div className='product-second'>
                <div className='title-box'>
                    <p className='title'>行业解决方案</p>
                    <p className='desc'>未来式智能致力于提供全栈业务解决方案，结合尖端技术、创新产品和专业服务，全方位赋能企业业务重塑。</p>
                </div>
                <div className='content-box'>
                    <Row gutter={[24,24]}>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='title'>
                                    <img src={ProductIcon1} alt="" />
                                    <span>电力能源</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>智能电网信息管理</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>负荷预测和调度研判</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>电力市场分析报告</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='title'>
                                    <img src={ProductIcon2} alt="" />
                                    <span>金融保险</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>AI 数字保险助理</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>交易监测和反欺诈</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>智能投资推荐</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='title'>
                                    <img src={ProductIcon3} alt="" />
                                    <span>泛互联网</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>网站 SEO 优化辅助</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>营销内容生成</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>系统办公自动化</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='title'>
                                    <img src={ProductIcon4} alt="" />
                                    <span>公共服务</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>智能审批和行政处理</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>社媒舆情分析</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>公文报告撰写辅助</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='title'>
                                    <img src={ProductIcon5} alt="" />
                                    <span>医疗健康</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>医学知识库沉淀</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>虚拟健康助手</span>
                                </p>
                                <p className='desc'>
                                    <img src={Correct} alt="" />
                                    <span>内部病历管理</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <p className='other'>
                                    <span>更多解决方案</span>
                                    <img src={ArrowRightIcon} alt="" />
                                </p>
                                <p className='expect'>
                                    <span>敬请期待</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    
                </div>
            </div>
            <div className='product-third'>
                <div className='title'>客户故事</div>
                <Affix offsetTop={100}>
                <div className='tab-box'>
                    <div className='tab'>
                        {
                            customerStoryList.map(item => {
                                return(
                                    <Link 
                                        key={item.id}
                                        onClick={() => handleCustomerTabClick(item)} 
                                        className={item.active ? 'item active': 'item'}
                                        to={item.link}
                                        spy={true}
                                        smooth={true}
                                        offset={-180}
                                        duration={500}
                                        onSetActive={handleSetActive}
                    
                                    >
                                        {item.label}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                </Affix>
                
                <div className='panel-box'>
                    <div id='socialMediaOperation' className='panel'>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>社媒运营 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>流水线式内容生产，助力AI全自动化营销</p>
                            <Divider />
                            <p className='desc'>平台通过接入主流社交媒体，结合生成式 AI 生成技术，能够自动进行营销内容的制作，排期和发布。该电商公司的社交平台粉丝增长 13.3%，点赞率提升 43%。提高整体社交媒体运营效率的同时，大幅降低人员成本投入。</p>
                        </div>
                        <div className='img-box'>
                            <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/socialMediaOperation.svg' alt="" />
                        </div>
                    </div>
                    <div id='gameCustomerService'  className='panel'>
                        <div className='img-box'>
                            <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/gameCustomerService.svg' alt="" />
                        </div>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>游戏客服 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>多任务模态，构建智能化服务体系</p>
                            <Divider />
                            <p className='desc'>某游戏公司通过LLM和AI Agents技术，继续少量人工投入即可快速搭建海外游戏垂直行业知识库，为玩家提供文字客服、语音客服、人工客服助手以及VIP玩家专属客服服务。相较传统客服方案，系统上线时间缩短70%，机器人拦截率提高20.8%，客户满意度提升23.5%，玩家流失率降低4.9%。</p>
                        </div>
                    </div>
                    <div id='contractReview'  className='panel'>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>合同审核 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>基于企业专有知识库，构建智能化解决方案</p>
                            <Divider />
                            <p className='desc'>某电网客户基于国内百亿级参数大语言预训练模型，搭建合规审核Agent，融合语言大模型、传统规则方法和RAG技术，在充分发挥大模型能力的同时保障结果的严谨性和效果。实现多文档比对、批量文档审核等功能。相比纯人工审核，实现合规文档覆盖率100%，审核效率提升95%以上，律师、业务专家咨询量降低50%+。</p>
                        </div>
                        <div className='img-box'>
                            <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/contractReview.svg' alt="" />
                        </div>
                    </div>
                    <div id='simulation'  className='panel'>
                        <div className='img-box'>
                            <img src={Simulation} alt="" />
                        </div>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>电力交易市场仿真模拟 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>创新应用，多智能体解决方案</p>
                            <Divider />
                            <p className='desc'>国内首个使用基于大语言模型的多智能体应用案例，创新性地将Agent技术与电力交易市场仿真模拟相结合，充分利用Agent的角色自定义、思考、学习反思优化能力，动态地模拟不同属性市场成员的报价行为，同时可以根据其他成员当前报价以及历史报价调整自己的报价策略，更能反映真实场景下用户的报价行为。</p>
                        </div>
                    </div>
                    <div id='flightReservation'  className='panel'>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>航班预订 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>基于自然语言构建全量业务流，提升交互体验</p>
                            <Divider />
                            <p className='desc'>某航空公司，将大模型与“灵搭”平台结合，构建一个航班预定Agent，改变传统GUI交互，用户通过自然语言对Agent对话便可完成航班预定，同时还可提供退票、改签、咨询等航空相关问题咨询服务。</p>
                        </div>
                        <div className='img-box'>
                            <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/flightReservation.svg' alt="" />
                        </div>
                    </div>
                    <div id='vaccineKnowledgeBase'  className='panel'>
                        <div className='img-box'>
                            <img src={VaccineKnowledgeBase} alt="" />
                        </div>
                        <div className='content'>
                            <p className='title-p'>
                                <span className='title'>疫苗知识库 </span>
                                <span className='agents'>Agent</span>
                            </p>
                            <p className='subhead'>将行业专家经验融入AI Agents，赋能企业员工</p>
                            <Divider />
                            <p className='desc'>某医疗企业，基于检索增强生成（RAG）技术，将国家政策解读文件、行业规范解读文件、公共卫生知识以及疫苗产品知识等材料转化为专业的疫苗知识库，搭建一个服务于20万基层卫生工作者的专业的疫苗知识库。基层卫生工作者随用随查，大大提高了其专业知识储备和工作效率，弥补了处于群众一线的医生在专业知识领域的不足。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoreProduct