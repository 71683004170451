import './index.scss'
import logo from '@/imgs/new/logo.svg'
import BusinessCooperation from '@/imgs/new/BusinessCooperation.svg'
import WechatPublicAccount from '@/imgs/new/WechatPublicAccount.svg'
import { Popover } from 'antd';

const Footer = () => {
    const handleOpenPage = (url) => {
        window.open(url, '_blank')
    }
    return(
        <div className='footer'>
            <div className='left'>
                <img className='logo' src={logo} alt="logo" />
                <div className='left-container'>
                    <span>北京未来式智能科技有限公司 AutoAgents.ai</span>
                    <span>© 2024 by AutoAgents Inc.</span>
                    <span className='curson-pointer' onClick={() => handleOpenPage('https://beian.miit.gov.cn/#/Integrated/index')}>京ICP备2024041827号</span>
                    <span className='curson-pointer' onClick={() => handleOpenPage('https://beian.mps.gov.cn/#/query/webSearch?code=11010802043879')}>京公网安备11010802043879</span>
                </div>
            </div>
            <div className='center'>
                <span className='center-title'>联系我们</span>
                <div className='center-container'>
                    <span>商务合作：service@autoagents.ai</span>
                    <span>简历投递：rayray@autoagents.ai</span>
                    <span>地址：北京市海淀区首享科技大厦</span>
                </div>
                
            </div>
            <div className='right'>
                <span className='right-title'>关注我们</span>
                <div className='code-box'>
                    <div className='code m-r-16'>
                        <Popover content={
                            <img style={{height: '180px', width: '180px'}} src={WechatPublicAccount} alt="" />
                        } title="">
                            <img className='code-img' src={WechatPublicAccount} alt="" />
                        </Popover>
                        <p className='code-title'>微信公众号</p>
                    </div>
                    <div className='code'>
                        <Popover content={
                            <img style={{height: '180px', width: '180px'}} src={BusinessCooperation} alt="" />
                        } title="">
                            <img className='code-img' src={BusinessCooperation} alt="" />
                        </Popover>
                        <p className='code-title'>商务合作</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer