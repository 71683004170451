import './index.scss'
import logo from '@/imgs/new/logo.svg'
import BusinessCooperation from '@/imgs/new/BusinessCooperation.svg'
import WechatPublicAccount from '@/imgs/new/WechatPublicAccount.svg'
import { Divider } from 'antd';

const Footer = () => {
    const handleOpenPage = (url) => {
        window.open(url, '_blank')
    }
    return(
        <div className='mobile-footer'>
            <div className='center'>
                <span className='center-title'>联系我们</span>
                <div className='center-container'>
                    <span>商务合作：service@autoagents.ai</span>
                    <span>简历投递：rayray@autoagents.ai</span>
                    <span>地址：北京市海淀区首享科技大厦</span>
                </div>
            </div>
            <div className='right'>
                <span className='right-title'>关注我们</span>
                <div className='code-box'>
                    <div className='code m-r-16'>
                        <img className='code-img' src={WechatPublicAccount} alt="" />
                        <p className='code-title'>微信公众号</p>
                    </div>
                    <div className='code'>
                        <img className='code-img' src={BusinessCooperation} alt="" />
                        <p className='code-title'>商务合作</p>
                    </div>
                </div>
            </div>
            <Divider style={{margin: '32px 0'}}></Divider>
            <div className='left'>
                <div className='left-container'>
                    <div className='text-center'>北京未来式智能科技有限公司｜AutoAgents.ai</div>
                    <div className='text-center m-b-t-10'>© 2024 by AutoAgents Inc.</div>
                    <div className='record'>
                        <span className='first-record' onClick={() => handleOpenPage('https://beian.miit.gov.cn/#/Integrated/index')}>京ICP备2024041827号</span>
                        <span onClick={() => handleOpenPage('https://beian.mps.gov.cn/#/query/webSearch?code=11010802043879')}>京公网安备11010802043879</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer