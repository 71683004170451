import './index.scss'
import RightArrowIcon from '@/imgs/new/RightArrowIcon.svg'
import IconCore1 from '@/imgs/new/IconCore1.svg'
import IconCore2 from '@/imgs/new/IconCore2.svg'
import IconCore3 from '@/imgs/new/IconCore3.svg'
import IconCore4 from '@/imgs/new/IconCore4.svg'
import IconCore5 from '@/imgs/new/IconCore5.svg'
import IconCore6 from '@/imgs/new/IconCore6.svg'
import UnactivatedAdvantageIcon from '@/imgs/new/UnactivatedAdvantageIcon.svg'
import ActiveaAdvantageIcon from '@/imgs/new/ActiveaAdvantageIcon.svg'
import ActiveCoreIcon from '@/imgs/new/ActiveCoreIcon.svg'
import UnactivatedCoreIcon from '@/imgs/new/UnactivatedCoreIcon.svg'
import TabPanelIcon1 from '@/imgs/new/TabPanelIcon1.svg'
import TabPanelIcon2 from '@/imgs/new/TabPanelIcon2.svg'
import TabPanelIcon3 from '@/imgs/new/TabPanelIcon3.svg'
import TabPanelIcon4 from '@/imgs/new/TabPanelIcon4.svg'
import TabPanelIcon5 from '@/imgs/new/TabPanelIcon5.svg'
import TabPanelIcon6 from '@/imgs/new/TabPanelIcon6.svg'
import TabPanelIcon7 from '@/imgs/new/TabPanelIcon7.svg'
import TabPanelIcon8 from '@/imgs/new/TabPanelIcon8.svg'
import TabPanelIcon9 from '@/imgs/new/TabPanelIcon9.svg'
import TabPanelIcon10 from '@/imgs/new/TabPanelIcon10.svg'
import TabPanelIcon11 from '@/imgs/new/TabPanelIcon11.svg'
import TabPanelIcon12 from '@/imgs/new/TabPanelIcon12.svg'
import { Col, Row, Tabs, ConfigProvider } from 'antd';
import { useState } from 'react'

const defaultPanes = [
    {
        label: `工作助手`,
        children: <div style={{textAlign: 'left', background: 'rgb(249, 250, 251)', padding: '16px', borderRadius: '8px'}}>
                    <img style={{height: '512px', width: '716px', borderRadius: '8px'}} src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/TabPanelContent1.gif' alt="" /> 
                    <div style={{ paddingTop: '12px', paddingBottom: '12px', color:'#184181', fontSize: '18px', lineHeight:'32px', width: '716px', textAlign: 'justify'}}>
                        基于特定的软件，人做指令，Agent做面向软件的操作，解决 “人机不对称”导致的效率低下问题。
                    </div>
                  </div>,
        key: 1,
    },
    {
        label: `业务自巡航`,
        children: <div style={{textAlign: 'left', background: 'rgb(249, 250, 251)', padding: '16px', borderRadius: '8px'}}>
                    <img style={{height: '512px', width: '716px', borderRadius: '8px'}} src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/TabPanelContent2.gif' alt="" /> 
                    <div style={{ paddingTop: '12px', paddingBottom: '12px', color:'#184181', fontSize: '18px', lineHeight:'32px', width: '716px', textAlign: 'justify'}}>
                        用户只需通过自然语言，就能借助Agent助手享受服务，是承接企业业务逻辑的一种新的形态，提供给终端用户更自然的体验。
                    </div>
                  </div>,
        key: 2,
    },
    {
        label: `自主智能体`,
        children: <div style={{textAlign: 'left', background: 'rgb(249, 250, 251)', padding: '16px', borderRadius: '8px'}}>
                    <img style={{height: '512px', width: '716px', borderRadius: '8px'}} src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/TabPanelContent4.gif' alt="" /> 
                    <div style={{ paddingTop: '12px', paddingBottom: '12px', color:'#184181', fontSize: '18px', lineHeight:'32px', width: '716px', textAlign: 'justify'}}>
                        一款更强大的自主智能体，具备完全自治的能力，可自主完成目标理解、规划、执行和反馈迭代多项任务，是行业内第一个在真实业务场景里跑通可用的自主智能体产品。
                    </div>
                  </div>,
        key: 3,
    },
    {
        label: `知识工作流水线`,
        children: <div style={{textAlign: 'left', background: 'rgb(249, 250, 251)', padding: '16px', borderRadius: '8px'}}>
                    <img style={{height: '512px', width: '716px', borderRadius: '8px'}} src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/TabPanelContent3.gif' alt="" /> 
                    <div style={{ paddingTop: '12px', paddingBottom: '12px', color:'#184181', fontSize: '18px', lineHeight:'32px', width: '716px', textAlign: 'justify'}}>
                        “人机结合超流水线”，将高价值复杂智慧服务拆解成人与Agent协作的工作流，通过巧妙编排、设置几百项子任务、流程、SOP、审核和质检，形成人机混合智能的复杂工作范式。
                    </div>
                  </div>,
        key: 4,
    }
]
const CoreTechnology = () => {
    
    const [tabs, setTabs] = useState([
        {
            id: 1,
            label: '平台优势',
            active: true,
            unactivatedIcon: UnactivatedAdvantageIcon,
            activeIcon: ActiveaAdvantageIcon
        },
        {
            id: 2,
            label: '平台核心能力',
            active: false,
            unactivatedIcon: UnactivatedCoreIcon,
            activeIcon: ActiveCoreIcon
        }
    ])

    const [tabPanel, setTabPanel] = useState(defaultPanes)
    
    const [selectItem, setSelectItem] = useState(1)


    const handleTabClick = (val) => {
        let list = JSON.parse(JSON.stringify(tabs))
        list.map(item => {
            if(item.label === val.label) {
                item.active = true
                setSelectItem(item.id)
            } else {
                item.active = false
            }
        })
        setTabs(list)
    }
    const handleOpenFeiShu = () => {
        window.open('https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc', '_blank')
    }
    return (
        <div className="core-technology">
            <div className="core-header">
                <img className='core-header-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/CoreTechnology1.svg' alt="" />
                <div className='core-header-content'>
                    <p className='title'>新一代企业级Agent平台——灵搭</p>
                    <p className='subhead'>首个自研企业级Agent应用构建平台</p>
                    <p className='desc'>国内领先的大模型场景应用平台，致力于企业级场景生态服务，推动企业新时代知识工作流水线的构建。</p>
                    <div className='button' onClick={handleOpenFeiShu}>
                        联系我们
                        <img src={RightArrowIcon} alt="" />
                    </div>
                </div>
            </div>
            <div className='core-first'>
                <div className='top'>
                    <p className='title'>新一代Agent技术架构</p>
                    <p className='subhead'>基于自研Multi-Agent架构满足不同类型Agent构建</p>
                    <p className='desc'>以原创Agent构建体系为核心，融合最新的人工智能进展，具备高度的灵活性、扩展性和智能化，快速打通并适配各个垂直场景中的业务应用，以满足不断变化的企业需求和行业挑战，为企业场景赋能。</p>
                </div>
                <div className='bottom'>
                    <Row gutter={[24, 32]}>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore1} alt="" />
                                <p>面向业务人员使用无代码的Agent产品</p>
                            </div>
                        </Col>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore2} alt="" />
                                <p>支持业务自然语言描述SOP，自动生成对应 Agent应用</p>
                            </div>
                        </Col>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore3} alt="" />
                                <p>支持各类国内外主流模型、支持模型之间混排使用</p>
                            </div>
                        </Col>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore4} alt="" />
                                <p>拥有专利权的行业第一个支持中心调度的“异步协作式”Agent组织</p>
                            </div>
                        </Col>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore5} alt="" />
                                <p>第一个在真实业务场景跑通可用的自主智能体产品</p>
                            </div>
                        </Col>
                        <Col span={12} className='flex-box'>
                            <div className='item'>
                                <img src={IconCore6} alt="" />
                                <p>针对Agent的微调技术（Agent-Tuning），且Agent的使用工具能力增强</p>
                            </div>
                        </Col>
                        
                    </Row>
                </div>
            </div>
            <div className='product-first'>
                <div className='title-box'>
                    <p className='title'>产品形态</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '88px'}}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Tabs: {
                                    inkBarColor: '#4060FF',
                                    itemColor: 'rgba(23, 15, 73, 0.8)',
                                    itemSelectedColor: '#170F49',
                                    itemHoverColor: '#170F49',
                                    titleFontSize: 18
                                },
                            },
                        }}
                        >
                        <Tabs
                            className='tab-box'
                            tabPosition='left'
                            items={tabPanel}
                            defaultActiveKey={1}
                        />
                    </ConfigProvider>
                </div>
            </div>
            <div className='core-second'>
                <div className='top'>
                    <p className='title'>自研技术平台——灵搭</p>
                    <p className='subhead'>企业级大模型应用构建平台</p>
                    <p className='desc'>灵搭平台集成国内外主流开源及闭源大语言模型，基于自研Mulit-Agent架构，构建企业级场景服务生态，为各类政企、行业客户等提供场景化解决方案。</p>
                </div>
                <div className='bottom'>
                    <div className='tab-box'>
                        {
                            tabs.map(item => {
                                return(
                                    <div className={item.active? 'item active' : 'item'} onClick={() => handleTabClick(item)}>
                                        <img src={item.active ? item.activeIcon : item.unactivatedIcon} alt="" />
                                        {item.label}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='tab-panel'>
                        {
                            selectItem === 1 && 
                            <div className='panel-item'>
                                <Row gutter={24}>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon1} alt="" />
                                            {/* <p className='title'>流程自主化</p> */}
                                            <p className='desc hover-desc'>面向业务人员的流程自主化，简单易操作，学习及使用成本低</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon2} alt="" />
                                            {/* <p className='title'>深度集成AI能力</p> */}
                                            <p className='desc hover-desc'>感知-决策-执行全流程覆盖 更智能</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon3} alt="" />
                                            {/* <p className='title'>多 Agents 协作</p> */}
                                            <p className='desc hover-desc'>场景更丰富，业务匹配更灵活</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon4} alt="" />
                                            {/* <p className='title'>可视化拖拽平台</p> */}
                                            <p className='desc hover-desc'>建构更便捷，操作更高效</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon5} alt="" />
                                            {/* <p className='title'>高度自定义编排</p> */}
                                            <p className='desc hover-desc'>满足复杂长流程任务实现</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon6} alt="" />
                                            {/* <p className='title'>工具使用简单</p> */}
                                            <p className='desc hover-desc'>多点接入更便捷、兼容性更强</p>
                                        </div>
                                    </Col>
                                    <Col span={6} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon7} alt="" />
                                            {/* <p className='title'>xxxx</p> */}
                                            <p className='desc hover-desc'>基于自然语言构建复杂Agent的能力，降低构建成本</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {
                            selectItem === 2 &&
                            <div className='panel-item'>
                                <Row gutter={24}>
                                    <Col span={8} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon8} alt="" />
                                            <p className='title'>知识库检索</p>
                                            <p className='desc'>优化企业内部优秀内容，沉淀为易于查询、人人可用的知识库，结合大语言模型的自然语言理解和通用知识能力，更方便、更高效实现信息检索、分析和处理。</p>
                                        </div>
                                    </Col>
                                    <Col span={8} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon9} alt="" />
                                            <p className='title'>模块化编排</p>
                                            <p className='desc'>平台提供丰富的模块与工具，支持拖拽连线操作，简化业务专属Agent的搭建，人人可编排，降低搭建门槛，更好适配业务需要。</p>
                                        </div>
                                    </Col>
                                    <Col span={8} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon10} alt="" />
                                            <p className='title'>多 Agent 联动</p>
                                            <p className='desc'>基于自研的 Multi-Agent 架构，支持Agent 间的联动调用、互联互通，扩充 Agent 执行判断能力，以满足更加复杂的应用场景设计。</p>
                                        </div>
                                    </Col>
                                    <Col span={8} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon11} alt="" />
                                            <p className='title'>开放能力</p>
                                            <p className='desc'>Agent 支持对外发布专属链接或专属 API，兼容适配主流设备，API 能力支持对接企业内部系统或主流办公软件，随时随地享受 AI Agents 所带来工作效率提升。</p>
                                        </div>
                                    </Col>
                                    <Col span={8} className='flex-box'>
                                        <div className='content'>
                                            <img className='item-icon' src={TabPanelIcon12} alt="" />
                                            <p className='title'>多模型调用</p>
                                            <p className='desc'>根据企业需要支持接入国内外主流模型，同时支持模型之间混排使用，更好发挥模型优势，提高 AI 处理的质量与稳定性。</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                        
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoreTechnology