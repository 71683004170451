import { useState, useEffect, useContext } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import './index.scss'
import blackLogo from '@/imgs/new/logo.svg'
import whiteLogo from '@/imgs/new/whiteLogo.svg'
import {GlobalContext} from '@/App.js'

const Header = () => {
    const {headerTheme} = useContext(GlobalContext)
    const [linkList, setLinkList] = useState([
        {
            label: '首页',
            link: '/',
        },
        {
            label: '产品介绍',
            link: '/CoreTechnology',
        },
        {
            label: '行业方案',
            link: '/CoreProduct',
        },
        {
            label: '关于我们',
            link: '/AboutUs',
        },
        {
            label: '加入我们',
            link: '/ContactUs',
        }
    ]);

    const [fontSize, setFontSize] = useState('16px')
    const routerLocation = useLocation()

    // 监听屏幕大小
    useEffect(() => {
        const handleResize = () => {
          if(window.innerWidth >= 920) {
            setFontSize('16px')
          } else {
            setFontSize('10px')
          }
        };
        handleResize()
        linkList.map(item => {
            if(item.link === routerLocation.pathname && item.link !== '/') {
                console.log('routerLocation.pathname:', routerLocation)
                document.title = `${item.label} | Autoagents`
            }
        })
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleOpenFeiShu = () => {
        window.open('https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc', '_blank')
    }

    const handleNavLinkClick = (label) => {
        window.scrollTo(0, 0)
        if(label === '首页') {
            document.title = `北京未来式智能科技有限公司 | AutoAgents.ai | AI Agents 大语言模型场景化落地产品服务提供商`
        } else {
            document.title = `${label} | Autoagents`
        }
    }

    return(
        <div className='page-header' style={headerTheme==='dark' ? {backdropFilter:'blur(15px)',WebkitBackdropFilter: 'blur(15px)',backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backdropFilter:'blur(0)',WebkitBackdropFilter: 'blur(0)',backgroundColor: 'rgba(255, 255, 255, 0)'}}>
            <div className='logo-box'>
                <img className='logo' src={headerTheme==='dark' ? blackLogo : whiteLogo} alt="logo" />
            </div>
            <div className='title-link-box'>
                {
                    linkList.map(item => {
                        return (
                            <NavLink 
                                key={item.label} 
                                className={ ({ isActive, isPending }) => isPending ? "pending" : isActive ? "active title-link" : "title-link" } 
                                to={item.link}
                                onClick={() => handleNavLinkClick(item.label)}
                                style={headerTheme==='dark' ? {fontSize: fontSize, color: '#170F49'} : {fontSize: fontSize, color: '#ffffff'}}
                            >
                                {item.label}
                            </NavLink>
                        )
                    })
                }
                <span className='title-link title-btn' style={{fontSize: fontSize}} onClick={handleOpenFeiShu}>联系我们</span>
            </div>
        </div>
    )
}

export default Header