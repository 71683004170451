import { createContext, useState } from 'react';
import Layout from './layout';
export const GlobalContext = createContext(null)
function App() {
  const [headerTheme, setHeaderTheme] = useState('light')
  return (
    <GlobalContext.Provider value={{headerTheme,setHeaderTheme}}>
      <div className="App">
        <Layout></Layout>
      </div>
    </GlobalContext.Provider>
    
  );
}

export default App;
