import Home from "@/pages/Home";
import CoreTechnology from "@/pages/CoreTechnology"
import CoreProduct from "@/pages/CoreProduct"
import AboutUs from "@/pages/AboutUs"
import ContactUs from "@/pages/ContactUs"
import MobileHome from "@/pages/MobileHome";
import MobileCoreTechnology from "@/pages/MobileCoreTechnology"
import MobileCoreProduct from "@/pages/MobileCoreProduct"
import MobileAboutUs from "@/pages/MobileAboutUs"
import MobileContactUs from "@/pages/MobileContactUs"

const pcRoutes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/CoreTechnology",
      element: <CoreTechnology />,
    },
    {
      path: "/CoreProduct",
      element: <CoreProduct />,
    },
    {
      path: "/AboutUs",
      element: <AboutUs />,
    },
    {
      path: "/ContactUs",
      element: <ContactUs />,
    }
];

const mobileRoutes = [
  {
    path: "/",
    element: <MobileHome />,
  },
  {
    path: "/CoreTechnology",
    element: <MobileCoreTechnology />,
  },
  {
    path: "/CoreProduct",
    element: <MobileCoreProduct />,
  },
  {
    path: "/AboutUs",
    element: <MobileAboutUs />,
  },
  {
    path: "/ContactUs",
    element: <MobileContactUs />,
  }
];

export { pcRoutes, mobileRoutes }