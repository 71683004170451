import './index.scss'
import { useState } from 'react'
import TimeIcon from '@/imgs/new/TimeIcon.svg'
import DegreeIcon from '@/imgs/new/DegreeIcon.svg'
import LocaltionIcon from '@/imgs/new/LocaltionIcon.svg'
import ArrowNarrowRightIcon from '@/imgs/new/ArrowNarrowRightIcon.svg'
import EmailIcon from '@/imgs/new/EmailIcon.svg'
import DrawerIcon1 from '@/imgs/new/drawerIcon1.svg'
import DrawerIcon2 from '@/imgs/new/drawerIcon2.svg'
import DrawerIcon3 from '@/imgs/new/drawerIcon3.svg'
import { Row, Col, Drawer, Divider } from 'antd'
const ContactUs = () => {
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(1)
    const showDrawer = (val) => {
        setActive(val)
        setOpen(true);
    }
    const onClose = () => {
        setOpen(false);
    }
    return(
        <>
            <div className='contact-us'>
                <div className="contact-header">
                    <img className='contact-header-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/ContactUsHeader.svg' alt="" />
                    <div className='contact-header-content'>
                        <p className='title'>未来式智能希望与社会各界人士共同探讨AGI的未来，探索Agent应用实践，共创更智能、更高效的未来生活图景。</p>
                    </div>
                </div>
                <div className='contact-first'>
                    <p className='title'>加入我们</p>
                    <div className='position-box'>
                        <Row gutter={[24, 32]}>
                            <Col span={12}>
                                <div className='item'>
                                    <div className='left'>
                                        <div className='title-box'>
                                            <div className='job-title'>AI算法工程师</div>
                                            <div className='social-recruitment'>社会招聘</div>
                                        </div>
                                        <div className='desc-box'>
                                            <div className='demand'>
                                                <img src={DegreeIcon} alt="" />
                                                <span>本科</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={TimeIcon} alt="" />
                                                <span>5年</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={LocaltionIcon} alt="" />
                                                <span>北京市海淀区</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <span onClick={() => showDrawer(1)}>查看岗位</span>
                                        <img onClick={() => showDrawer(1)} src={ArrowNarrowRightIcon} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='item'>
                                    <div className='left'>
                                        <div className='title-box'>
                                            <div className='job-title'>产品市场经理</div>
                                            <div className='social-recruitment'>社会招聘</div>
                                        </div>
                                        <div className='desc-box'>
                                            <div className='demand'>
                                                <img src={DegreeIcon} alt="" />
                                                <span>本科</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={TimeIcon} alt="" />
                                                <span>5年</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={LocaltionIcon} alt="" />
                                                <span>北京市海淀区</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <span onClick={() => showDrawer(2)}>查看岗位</span>
                                        <img onClick={() => showDrawer(2)} src={ArrowNarrowRightIcon} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='item'>
                                    <div className='left'>
                                        <div className='title-box'>
                                            <div className='job-title'>AI Agent 产品总监</div>
                                            <div className='social-recruitment'>社会招聘</div>
                                        </div>
                                        <div className='desc-box'>
                                            <div className='demand'>
                                                <img src={DegreeIcon} alt="" />
                                                <span>本科</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={TimeIcon} alt="" />
                                                <span>5年</span>
                                            </div>
                                            <div className='demand'>
                                                <img src={LocaltionIcon} alt="" />
                                                <span>北京市海淀区</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <span onClick={() => showDrawer(3)}>查看岗位</span>
                                        <img onClick={() => showDrawer(3)} src={ArrowNarrowRightIcon} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='contact-second'>
                    <img src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/pcIntroduceBg.svg' alt="" />
                    <div className='contact-second-content'>
                        <div className='title'>探索无限可能，与<span style={{color: 'rgba(64, 96, 255, 1)'}}>未来式智能</span>同行</div>
                        <div>
                            未来式智能，领先的 AI Agents 行业创新者，致力于通过前沿技术和卓越服务塑造未来。我们相信，每一位员工都是实现这一愿景的关键。
                        </div>
                        <div>
                            在这里，你将体验到：
                        </div>
                        <div>
                            <span className='label-title'>· 创新文化：</span>一个鼓励创意自由和个人成长的工作环境
                        </div>
                        <div>
                            <span className='label-title'>· 发展机会：</span>多样化的职业路径，支持你不断挑战自我，实现职业抱负
                        </div>
                        <div>
                           <span className='label-title'>· 平衡生活：</span>灵活的工作安排，让你在追求事业成功的同时，享受生活
                        </div>
                        <div>
                            <span className='label-title'>· 优厚待遇：</span>具有竞争力的薪酬和福利，确保你的每一份努力都得到认可
                        </div>
                        <div style={{paddingTop: '12px'}}>
                            加入我们，成为推动变革的力量，共同创造一个更加美好的世界。
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                title=""
                placement='left'
                width={'60%'}
                onClose={onClose}
                open={open}
                closable={false}
            >
                {active === 1 && Job1()}
                {active === 2 && Job2()}
                {active === 3 && Job3()}
            </Drawer>
        </>
    )
}

const Job1 = () => {
    return(
        <div className='drawer-content'>
            <div className='drawer-content-header'>
                <div className='title'>AI算法工程师</div>
                <div className='subhead'>
                    <div className='desc-box'>
                        <div className='demand'>
                            <img src={DegreeIcon} alt="" />
                            <span>本科</span>
                        </div>
                        <div className='demand'>
                            <img src={TimeIcon} alt="" />
                            <span>5年</span>
                        </div>
                        <div className='demand'>
                            <img src={LocaltionIcon} alt="" />
                            <span>北京市海淀区</span>
                        </div>
                    </div>
                    <div className='email-box'>
                        <img className='email-icon' src={EmailIcon} alt="" />
                        <span className='email-title'>rayray@autoagents.ai</span>
                    </div>
                </div>
            </div>
            <Divider />
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon1} alt="" />
                    <span>岗位职责：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 研究前沿LLM、多模态、NLP、AIGC到应用的转化和算法的优化。</p>
                    <p className='jd-item'>- 评测各类AIGC相关模型工具的性能和效果，提出改进和优化方案。</p>
                    <p className='jd-item'>- 与研发团队合作，参与算法的设计、开发和优化。</p>
                    <p className='jd-item'>- 将AIGC模型工具应用到我们的产品中，提高产品的性能和用户体验。</p>
                    <p className='jd-item'>- 解决各类技术挑战，确保项目的顺利进行。</p>
                    <p className='jd-item'>- 与团队成员密切合作，共同推动项目的进展。</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon2} alt="" />
                    <span>职位要求：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 计算机科学或相关领域的学士或以上学位。</p>
                    <p className='jd-item'>- 扎实的算法和编程能力，熟悉常见的算法和数据结构。</p>
                    <p className='jd-item'>- 熟悉AIGC技术和相关领域的最新研究进展。</p>
                    <p className='jd-item'>- 熟悉常用的机器学习和深度学习框架，如TensorFlow、PyTorch等。</p>
                    <p className='jd-item'>- 具备良好的问题解决能力和团队合作精神。</p>
                    <p className='jd-item'>- 具备较强的沟通能力和英文文档阅读能力</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon3} alt="" />
                    <span>加分项：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 对人工智能、机器学习或深度学习有实际项目经验</p>
                    <p className='jd-item'>- 有使用大型语言模型(如GPT系列)的经验</p>
                    <p className='jd-item'>- 有开源项目或者对外发表的技术文章</p>
                </div>
            </div>
            <div className='welcome'>
                如果您对我们的公司和这个岗位感兴趣，请将您的简历发送至我们的邮箱，并注明应聘岗位。我们期待与您共同开创美好的未来！
            </div>
        </div>
    )
}

const Job2 = () => {
    return(
        <div className='drawer-content'>
            <div className='drawer-content-header'>
                <div className='title'>产品市场经理</div>
                <div className='subhead'>
                    <div className='desc-box'>
                        <div className='demand'>
                            <img src={DegreeIcon} alt="" />
                            <span>本科</span>
                        </div>
                        <div className='demand'>
                            <img src={TimeIcon} alt="" />
                            <span>5年</span>
                        </div>
                        <div className='demand'>
                            <img src={LocaltionIcon} alt="" />
                            <span>北京市海淀区</span>
                        </div>
                    </div>
                    <div className='email-box'>
                        <img className='email-icon' src={EmailIcon} alt="" />
                        <span className='email-title'>rayray@autoagents.ai</span>
                    </div>
                </div>
            </div>
            <Divider />
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon1} alt="" />
                    <span>岗位职责：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 制定和执行年度市场推广计划，确保与公司整体战略目标一致。</p>
                    <p className='jd-item'>- 分析市场趋势，竞争对手动态，以及客户需求，为产品定位和市场细分提供数据支持。</p>
                    <p className='jd-item'>- 负责公司公众号的内容策划、撰写和编辑，确保内容质量和传播效果。</p>
                    <p className='jd-item'>- 管理内容发布日程，定期发布行业洞察、产品更新、案例研究等高质量内容。</p>
                    <p className='jd-item'>- 策划并执行线上线下品牌推广活动，包括行业展会、研讨会、网络研讨会等。</p>
                    <p className='jd-item'>- 建立和维护与行业媒体、分析师、意见领袖的关系，利用媒体资源进行品牌故事讲述和产品宣传。</p>
                    <p className='jd-item'>- 识别并建立与行业合作伙伴的合作关系，共同开发市场推广活动。</p>
                    <p className='jd-item'>- 管理合作伙伴关系，确保合作项目的成功执行和双方利益最大化。</p>
                    <p className='jd-item'>- 分析市场活动的效果，评估ROI，优化市场投入产出比。</p>
                    <p className='jd-item'>- 与其他部门（如产品、销售、客服等）紧密合作，确保市场活动与公司其他业务流程的协同。</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon2} alt="" />
                    <span>职位要求：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 拥有市场营销、传媒、新闻或相关专业本科以上学历。</p>
                    <p className='jd-item'>- 3年以上AI行业或相关领域的市场推广经验。</p>
                    <p className='jd-item'>- 有业务sense，出色的文案撰写能力和内容创作能力，熟悉公众号等社交媒体平台的运营。</p>
                    <p className='jd-item'>- 良好的媒体资源和行业网络，能够有效地进行品牌推广和公关活动。</p>
                    <p className='jd-item'>- 具备数据分析能力，能够通过市场分析指导市场策略的调整。</p>
                    <p className='jd-item'>- 优秀的沟通、协调和团队管理能力，能够在快节奏的环境中工作。</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon3} alt="" />
                    <span>加分项：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 有责任心和良好的团队合作精神。</p>
                    <p className='jd-item'>- 能够承受一定的工作压力，对挑战有积极的态度。</p>
                    <p className='jd-item'>- 有优秀的组织和时间管理技能。</p>
                </div>
            </div>
            <div className='welcome'>
                如果您对我们的公司和这个岗位感兴趣，请将您的简历发送至我们的邮箱，并注明应聘岗位。我们期待与您共同开创美好的未来！
            </div>
        </div>
    )
}

const Job3 = () => {
    return(
        <div className='drawer-content'>
            <div className='drawer-content-header'>
                <div className='title'>AI Agent 产品总监</div>
                <div className='subhead'>
                    <div className='desc-box'>
                        <div className='demand'>
                            <img src={DegreeIcon} alt="" />
                            <span>本科</span>
                        </div>
                        <div className='demand'>
                            <img src={TimeIcon} alt="" />
                            <span>5年</span>
                        </div>
                        <div className='demand'>
                            <img src={LocaltionIcon} alt="" />
                            <span>北京市海淀区</span>
                        </div>
                    </div>
                    <div className='email-box'>
                        <img className='email-icon' src={EmailIcon} alt="" />
                        <span className='email-title'>rayray@autoagents.ai</span>
                    </div>
                </div>
            </div>
            <Divider />
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon1} alt="" />
                    <span>岗位职责：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 结合大语言模型应用技术的发展趋势和市场需求，制定产品的发展战略和路线图。</p>
                    <p className='jd-item'>- 负责收集和分析客户需求，设计符合市场需求的产品功能和解决方案。</p>
                    <p className='jd-item'>- 组织跨部门团队进行产品研发，确保产品的开发进度和质量，推动产品顺利上线。</p>
                    <p className='jd-item'>- 通过收集和分析产品相关的用户反馈和数据，对产品进行持续优化和迭代。</p>
                    <p className='jd-item'>- 与合作伙伴、行业组织等保持良好关系，共建大语言模型应用生态圈。</p>
                    <p className='jd-item'>- 负责产品的项目管理，包括制定项目计划、预算和资源管理。</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon2} alt="" />
                    <span>职位要求：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 本科及以上学历，计算机、互联网、人工智能、大数据等相关专业优先。</p>
                    <p className='jd-item'>- 至少有5年以上产品管理经验，有成熟的大模型成功的产品设计和推广经验者优先。</p>
                    <p className='jd-item'>- 熟悉大语言模型应用领域的技术和发展趋势，了解相关行业标准和规范。</p>
                    <p className='jd-item'>- 具备出色的项目管理能力和协调能力，能够有效地跨部门运作。</p>
                    <p className='jd-item'>- 具备数据分析和解决问题的能力，能够熟练运用数据分析工具。</p>
                    <p className='jd-item'>- 对新技术和新趋势保持敏感，具备创新思维和学习能力。</p>
                    <p className='jd-item'>- 出色的沟通和表达能力，能够有效地与不同层次的团队成员和其他部门沟通。</p>
                    <p className='jd-item'>- 熟练使用英语进行工作交流者优先考虑。</p>
                </div>
            </div>
            <div className='drawer-content-item'>
                <div className='title'>
                    <img src={DrawerIcon3} alt="" />
                    <span>加分项：</span>
                </div>
                <div className='jd'>
                    <p className='jd-item'>- 有责任心和良好的团队合作精神。</p>
                    <p className='jd-item'>- 能够承受一定的工作压力，对挑战有积极的态度。</p>
                    <p className='jd-item'>- 有优秀的组织和时间管理技能。</p>
                </div>
            </div>
            <div className='welcome'>
                如果您对我们的公司和这个岗位感兴趣，请将您的简历发送至我们的邮箱，并注明应聘岗位。我们期待与您共同开创美好的未来！
            </div>
        </div>
    )
}


export default ContactUs