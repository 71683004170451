import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Mousewheel } from 'swiper/modules';
import { useState, useEffect, useRef, useContext } from 'react'
import { useLocation } from "react-router-dom";
import {GlobalContext} from '@/App.js'
import {Row, Col} from 'antd'
import {
    UpOutlined,
    DownOutlined
  } from '@ant-design/icons';

import './index.scss'
import 'swiper/css/bundle'


import FirstTitle from '@/imgs/new/FirstTitle.svg'
import RightArrowIcon from '@/imgs/new/RightArrowIcon.svg'
import Icon1 from '@/imgs/new/icon1.svg'
import Icon2 from '@/imgs/new/icon2.svg'
import Icon3 from '@/imgs/new/icon3.svg'
import Icon4 from '@/imgs/new/icon4.svg'
import Icon5 from '@/imgs/new/icon5.svg'
import Icon6 from '@/imgs/new/icon6.svg'
import Icon7 from '@/imgs/new/icon7.svg'
import Icon8 from '@/imgs/new/icon8.svg'
import Icon9 from '@/imgs/new/icon9.svg'
import IconTime from '@/imgs/new/IconTime.svg'
import Bg2 from '@/imgs/new/Bg2.svg'

const Home = () => {
    const {setHeaderTheme} = useContext(GlobalContext)
    const routerLocation = useLocation()
    const videoRef = useRef(null)
     // 监听屏幕是否滚动
     useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY
            if(scrollY >= videoRef.current.clientHeight) {
                setHeaderTheme('dark')
            } else {
                setHeaderTheme('light')
            }
            console.log(scrollY, videoRef, 'scrollY')
        };

        window.addEventListener('scroll', handleScroll);

        // 清理滚动事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setHeaderTheme('light')
    }, [routerLocation])
    const handleOpenPage = (url) => {
        window.open(url, '_blank')
    }
    const handleOpenFeiShu = () => {
        window.open('https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc', '_blank')
    }
    const [collapseItem, setCollapseItem] = useState([
        {
            id: 1,
            label: '电力能源',
            icon: Icon3,
            desc: '面向行业头部客户开展深度合作。基于行业专有大模型的底座，借助Agent优化全量业务流程，通过轻量化+私有化部署、高度场景化适配、低成本开发+维护的整合应用能力，打造全栈解决方案与服务，树立行业标杆，引领数智化转型升级。',
            isCollapse: true
        },
        {
            id: 2,
            label: '金融保险',
            icon: Icon4,
            desc: '打造行业领先的金融Agent创新解决方案。通过Agent构建自主可控的金融服务体系，赋能智能客服、智能投顾、投研辅助到智能营销等关键业务场景，提供全套工具与服务，以降低成本，提高生产效率，提升综合金融服务能力。',
            isCollapse: true
        },
        {
            id: 3,
            label: '泛互联网',
            icon: Icon5,
            desc: '专注于超级自动化链路构建，提供Agent自主化营销解决方案，包括内容生成、个人办公助理、自动化流程和知识工作流优化等；借助多Agent协作，为个人和企业创造高价值内容，推动人机混合协作的新工作模式，释放产业潜力。',
            isCollapse: true
        },
        {
            id: 4,
            label: '公共服务',
            icon: Icon6,
            desc: '为政务、办公、交通等公共领域客户提供定制的场景化Agent解决方案，重塑业务流程。利用数据分析、文档处理和多模态交互等技术，打造智能助手、智能问答及文档处理等Agent，降低人力成本，提高服务质量，从而促进整个社会的效率提升。',
            isCollapse: true
        },
        {
            id: 5,
            label: '医疗健康',
            icon: Icon7,
            desc: '切入垂直领域，直击痛点问题，进行创新Agent服务。基于产业级医疗大模型，利用知识问答、文档处理、辅助决策等能力，构建知识库Agent、健康助手Agent、病例管理Agent等，提供准确、个性化的服务，推动智能化医疗健康体系的建立与完善。',
            isCollapse: true
        },
        {
            id: 6,
            label: '更多行业',
            icon: Icon8,
            desc: '我们将继续拓展新行业与场景，深化与企业的合作，带来更多创新方案。',
            isCollapse: true
        }
        
    ])

    const handleOpenCollapseItem = (val) => {
        console.log('open')
        let list = JSON.parse(JSON.stringify(collapseItem))
        list.map(item => {
            if(item.id === val.id) {
                item.isCollapse = true
            }
        })
        setCollapseItem(list)
    }
    const handleCloseCollapseItem = (val) => {
        console.log('close')
        let list = JSON.parse(JSON.stringify(collapseItem))
        list.map(item => {
            if(item.id === val.id) {
                item.isCollapse = false
            }
        })
        setCollapseItem(list)
    }
    return(
        <div className='mobile-home'>
            <div className='first'>
                <div className='first-title-box'>
                    {/* <img src={FirstTitle} alt="" /> */}
                    <div className='first-title'>
                        <div className='title'>AI Agents</div>
                        <div className='subhead'>AI Agents引领未来智能变革</div>
                        <div className='desc'>AGI新纪元，构建企业级Agent全新生态图景</div>
                    </div>
                    <div className='button' onClick={handleOpenFeiShu}>
                        联系我们
                        <img src={RightArrowIcon} alt="" />
                    </div>
                </div>
                <div ref={videoRef} className='gif-box'>
                    <img src="https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/mobileHomeGif.gif" alt="" />
                {/* <video width={'100%'} autoPlay loop muted>
                    <source src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/gif750.mp4' type="video/mp4" />
                </video> */}
                </div>
            </div>
            <div className='second'>
                <Row style={{padding: '0 16px'}}>
                    <Col span={24}>
                        <div className='second-left-box'>
                            <p className='second-title'>领跑国内AI Agents，树立行业先锋</p>
                            <p className='second-desc'>国内首批企业级Agent场景化产品服务提供商</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='second-right-box'>
                            <div className='second-content m-b-20'>
                                <div className='second-content-title'>
                                    <img src={Icon1} alt="" />
                                    <span>专注于企业服务</span>
                                </div>
                                <p className='second-content-desc'>致力于为全球企业提供自主智能体（AI Agents）以及智能助理（Copilot）软件服务，打造人与AI智能混合工作新范式</p>
                            </div>
                            
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='second-right-box'>
                            <div className='second-content'>
                                <div className='second-content-title'>
                                    <img src={Icon2} alt="" />
                                    <span>专注于Agent技术落地</span>
                                </div>
                                <p className='second-content-desc'>引领行业前沿技术应用，涵盖能源电力、金融保险、泛互联网、公共服务、医疗健康等多元业务场景，率先打造行业级解决方案，致力于解决大语言模型到场景化落地应用“最后一公里”</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                
            </div>
            <div className='third'>
                <div className='third-title'>
                    <p className='title'>用AI Agents重塑企业全量价值链</p>
                    <p className='desc'>颠覆传统固化业务流，人与AI混合协同的知识工作流水线</p>
                </div>
                <div className='third-content'>
                    <Row>
                        {
                            collapseItem.map(item => {
                                return (
                                    <Col key={item.id} span={24} className='flex-box'>
                                        <div className='item'>
                                            <div className='icon-box'>
                                                <img src={item.icon} alt="" />
                                            </div>
                                            <div className='title'>
                                                {item.label}
                                                {item.isCollapse ? <UpOutlined style={{color: 'gray'}} onClick={() => handleCloseCollapseItem(item)} /> : <DownOutlined onClick={() => handleOpenCollapseItem(item)} style={{color: 'gray'}} />}
                                            </div>
                                            {
                                                item.isCollapse && 
                                                <div className='desc'>
                                                    {item.desc}
                                                    {
                                                        item.id === 6 && <><br /> 敬请期待！</>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    
                        {/* <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon3} alt="" />
                                </div>
                                <div className='title'>电力能源</div>
                                <div className='desc'>
                                    面向行业头部客户开展深度合作。基于行业专有大模型的底座，借助Agent优化全量业务流程，通过轻量化+私有化部署、高度场景化适配、低成本开发+维护的整合应用能力，打造全栈解决方案与服务，树立行业标杆，引领数智化转型升级。
                                </div>
                            </div>
                        </Col>
                        <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon4} alt="" />
                                </div>
                                <p className='title'>金融保险</p>
                                <p className='desc'>
                                打造行业领先的金融Agent创新解决方案。通过Agent构建自主可控的金融服务体系，赋能智能客服、智能投顾、投研辅助到智能营销等关键业务场景，提供全套工具与服务，以降低成本，提高生产效率，提升综合金融服务能力。
                                </p>
                            </div>
                        </Col>
                        <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon5} alt="" />
                                </div>
                                <p className='title'>泛互联网</p>
                                <p className='desc'>
                                专注于超级自动化链路构建，提供Agent自主化营销解决方案，包括内容生成、个人办公助理、自动化流程和知识工作流优化等；借助多Agent协作，为个人和企业创造高价值内容，推动人机混合协作的新工作模式，释放产业潜力。
                                </p>
                            </div>
                        </Col>
                        <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon6} alt="" />
                                </div>
                                <p className='title'>公共服务</p>
                                <p className='desc'>
                                为政务、办公、交通等公共领域客户提供定制的场景化Agent解决方案，重塑业务流程。利用数据分析、文档处理和多模态交互等技术，打造智能助手、智能问答及文档处理等Agent，降低人力成本，提高服务质量，从而促进整个社会的效率提升。
                                </p>
                            </div>
                        </Col>
                        <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon7} alt="" />
                                </div>
                                <p className='title'>医疗健康</p>
                                <p className='desc'>
                                切入垂直领域，直击痛点问题，进行创新Agent服务。基于产业级医疗大模型，利用知识问答、文档处理、辅助决策等能力，构建知识库Agent、健康助手Agent、病例管理Agent等，提供准确、个性化的服务，推动智能化医疗健康体系的建立与完善。
                                </p>
                            </div>
                        </Col>
                        <Col span={24} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon8} alt="" />
                                </div>
                                <p className='title'>更多行业</p>
                                <p className='desc'>
                                    我们将继续拓展新行业与场景，深化与企业的合作，带来更多创新方案。<br/>敬请期待！
                                </p>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>
            <div className='fourthly'>
                <img src={Bg2} alt="" />
                <div className='fourthly-title'>
                    <p className='title'>驱动未来生产力 打造全新工作范式</p>
                    <p className='desc'>让全球10亿知识工作者10倍效工作</p>
                </div>
                <div className='fourthly-content'>
                    <div className='item m-b-20'>
                        <div className='title'>
                            <img src={Icon9} alt="" />
                            为企业
                        </div>
                        <p className='desc'>搭建业务新入口，拓展新的增量市场</p>
                        <div className='list'>
                            <div className='list-item-box'>
                                <div className='list-item'>业务决策与执行</div>
                                <div className='list-item'>知识流水线生产</div>
                                <div className='list-item'>增量市场价值赋能</div>
                            </div>
                        </div>
                    </div>
                    <div className='item m-b-60'>
                        <div className='title'>
                            <img src={Icon9} alt="" />
                            为个人
                        </div>
                        <p className='desc'>提供更智能、更高效、更好的体验</p>
                        <div className='list'>
                            <div className='list-item-box'>
                                <div className='list-item'>智能决策</div>
                                <div className='list-item'>业务流优化</div>
                                <div className='list-item'>知识生产管理</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fifth'>
                <div className='fifth-title-box'>
                    <p className='title'>企业动态</p>
                    <p className='desc'>专注AI Agents技术的创新与应用，致力于推动全球知识工作者的智能革命</p>
                </div>
                <Swiper
                    modules={[Autoplay, Mousewheel]}
                    slidesPerView={2}
                    spaceBetween={0}
                    loop={true} // 开启循环滚动
                    autoplay={{
                        delay: 1500, // 自动播放间隔时间，单位为毫秒
                        disableOnInteraction: true, // 用户操作后是否禁用自动播放
                        pauseOnMouseEnter: true
                    }}
                >
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://baijiahao.baidu.com/s?id=1786213651332159441&wfr=spider&for=pc')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper1.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>36氪首发｜打造办公AI Agents，「AutoAgents」完成数千万元天使轮融资</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-25
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/2ooM12oNLa_HIbaKksK0-g')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper2.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>专访AutoAgents.ai杨劲松：解锁人类意图的Agent型软件，将开启企业服务新纪元</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-26
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://www.geekpark.net/news/328530')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper3.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>甲子引力年终盛典 | 未来式智能 CEO 杨劲松受邀出席，探讨 AI Agents 未来趋势</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-04
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://m.huanqiu.com/article/4FligbfDQ62')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper4.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>中国信通院智能体标准研讨会召开 未来式CEO杨劲松出席参与标准制定</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-15
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/uVIqAEQZjxXO-aocTUweWg')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper5.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能受邀参与行业会议，探讨汽车行业的AI创新之路</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-01-05 
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/lGhfEPAt1oXtYi9viVNcjQ')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper6.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>CEO杨劲松受邀出席AIGC发展高峰论坛，未来式智能火爆会场</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-02
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/cURf8G_tiFMVRl0FiSt9HA')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper7.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能与国网成都供电公司深度合作，全面推进大模型技术应用</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-07
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/Xwh5CBJ3TgiNqlWMOR875g')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper8.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>海淀区政协与人工智能企业深度交流，未来式智能获高度关注</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-22
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Home