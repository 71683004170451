import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Mousewheel } from 'swiper/modules';
import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from "react-router-dom";
import {Row, Col} from 'antd'

import './index.scss'
import 'swiper/css/bundle'


import FirstTitle from '@/imgs/new/FirstTitle.svg'
import RightArrowIcon from '@/imgs/new/RightArrowIcon.svg'
import Icon1 from '@/imgs/new/icon1.svg'
import Icon2 from '@/imgs/new/icon2.svg'
import Icon3 from '@/imgs/new/icon3.svg'
import Icon4 from '@/imgs/new/icon4.svg'
import Icon5 from '@/imgs/new/icon5.svg'
import Icon6 from '@/imgs/new/icon6.svg'
import Icon7 from '@/imgs/new/icon7.svg'
import Icon8 from '@/imgs/new/icon8.svg'
import Icon9 from '@/imgs/new/icon9.svg'
import IconTime from '@/imgs/new/IconTime.svg'

import {GlobalContext} from '@/App.js'


const Home = () => {
    const {setHeaderTheme} = useContext(GlobalContext)
    const handleOpenPage = (url) => {
        window.open(url, '_blank')
    }
    const handleOpenFeiShu = () => {
        window.open('https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc', '_blank')
    }
    const [swiperCount, setSwiperCount] = useState(4)
    const routerLocation = useLocation()
    useEffect(() => {
        const handleResize = () => {
          if(window.innerWidth >= 1500) {
            setSwiperCount(4)
          } else if (window.innerWidth >= 1140) {
            setSwiperCount(3)
          } else {
            setSwiperCount(2)
          }
        };
        handleResize()
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const videoRef = useRef(null)

    // 监听屏幕是否滚动
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY
            if(scrollY >= videoRef.current.clientHeight) {
                setHeaderTheme('dark')
            } else {
                setHeaderTheme('light')
            }
            console.log(scrollY, videoRef, 'scrollY')
        };

        window.addEventListener('scroll', handleScroll);

        // 清理滚动事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setHeaderTheme('light')
    }, [routerLocation])
    return(
        <div className='home'>
            <div className='first'>
                <div className='first-title-box'>
                    {/* <img src={FirstTitle} alt="" /> */}
                    <div className='first-desc'>
                        <div className='label'>AI Agents</div>
                        <div className='title'>AI Agents引领未来智能变革</div>
                        <div className='desc'>AGI新纪元，构建企业级Agent全新生态图景</div>
                    </div>
                    <div className='button' onClick={handleOpenFeiShu}>
                        联系我们
                        <img src={RightArrowIcon} alt="" />
                    </div>
                </div>
                <div ref={videoRef} className='gif-box'>
                    <video width={'100%'} autoPlay loop muted>
                        <source src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/gif1980.mp4' type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className='second'>
                <Row>
                    <Col span={2}></Col>
                    <Col span={10} style={{display: 'flex', alignItems: 'center'}}>
                        <div className='second-left-box'>
                            <p className='second-title'>领跑国内AI Agents</p>
                            <p className='second-title'>树立行业先锋</p>
                            <p className='second-desc'>国内首批企业级Agent场景化产品服务提供商</p>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className='second-right-box'>
                            <div className='second-content m-b-40 translation-r-20'>
                                <div className='second-content-title'>
                                    <img src={Icon1} alt="" />
                                    <span>专注于企业服务</span>
                                </div>
                                <p className='second-content-desc'>致力于为全球企业提供自主智能体（AI Agents）以及智能助理（Copilot）软件服务，打造人与AI智能混合工作新范式</p>
                            </div>
                            <div className='second-content'>
                                <div className='second-content-title'>
                                    <img src={Icon2} alt="" />
                                    <span>专注于Agent技术落地</span>
                                </div>
                                <p className='second-content-desc'>引领行业前沿技术应用，涵盖能源电力、金融保险、泛互联网、公共服务、医疗健康等多元业务场景，率先打造行业级解决方案，致力于解决大语言模型到场景化落地应用“最后一公里”</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                </Row>
                
                
            </div>
            <div className='third'>
                <div className='third-title'>
                    <p className='title'>用AI Agents重塑企业全量价值链</p>
                    <p className='desc'>颠覆传统固化业务流，人与AI混合协同的知识工作流水线</p>
                </div>
                <div className='third-content'>
                    <Row gutter={[24, 0]}>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon3} alt="" />
                                </div>
                                <p className='title'>电力能源</p>
                                <p className='desc'>
                                    面向行业头部客户开展深度合作。基于行业专有大模型的底座，借助Agent优化全量业务流程，通过轻量化+私有化部署、高度场景化适配、低成本开发+维护的整合应用能力，打造全栈解决方案与服务，树立行业标杆，引领数智化转型升级。
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon4} alt="" />
                                </div>
                                <p className='title'>金融保险</p>
                                <p className='desc'>
                                打造行业领先的金融Agent创新解决方案。通过Agent构建自主可控的金融服务体系，赋能智能客服、智能投顾、投研辅助到智能营销等关键业务场景，提供全套工具与服务，以降低成本，提高生产效率，提升综合金融服务能力。
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon5} alt="" />
                                </div>
                                <p className='title'>泛互联网</p>
                                <p className='desc'>
                                专注于超级自动化链路构建，提供Agent自主化营销解决方案，包括内容生成、个人办公助理、自动化流程和知识工作流优化等；借助多Agent协作，为个人和企业创造高价值内容，推动人机混合协作的新工作模式，释放产业潜力。
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon6} alt="" />
                                </div>
                                <p className='title'>公共服务</p>
                                <p className='desc'>
                                为政务、办公、交通等公共领域客户提供定制的场景化Agent解决方案，重塑业务流程。利用数据分析、文档处理和多模态交互等技术，打造智能助手、智能问答及文档处理等Agent，降低人力成本，提高服务质量，从而促进整个社会的效率提升。
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon7} alt="" />
                                </div>
                                <p className='title'>医疗健康</p>
                                <p className='desc'>
                                切入垂直领域，直击痛点问题，进行创新Agent服务。基于产业级医疗大模型，利用知识问答、文档处理、辅助决策等能力，构建知识库Agent、健康助手Agent、病例管理Agent等，提供准确、个性化的服务，推动智能化医疗健康体系的建立与完善。
                                </p>
                            </div>
                        </Col>
                        <Col span={8} className='flex-box'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <img src={Icon8} alt="" />
                                </div>
                                <p className='title'>更多行业</p>
                                <p className='desc'>
                                    我们将继续拓展新行业与场景，深化与企业的合作，带来更多创新方案。<br/>敬请期待！
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='fourthly'>
                <div className='fourthly-title'>
                    <p className='title'>驱动未来生产力 打造全新工作范式</p>
                    <p className='desc'>让全球10亿知识工作者10倍效工作</p>
                </div>
                <div className='fourthly-content'>
                    <div className='item m-r-24'>
                        <div className='title'>
                            <img src={Icon9} alt="" />
                            为企业
                        </div>
                        <p className='desc'>搭建业务新入口，拓展新的增量市场</p>
                        <div className='list'>
                            <div className='list-item-box'>
                                <div className='list-item'>业务决策与执行</div>
                                <div className='list-item'>知识流水线生产</div>
                                <div className='list-item'>增量市场价值赋能</div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='title'>
                            <img src={Icon9} alt="" />
                            为个人
                        </div>
                        <p className='desc'>提供更智能、更高效、更好的体验</p>
                        <div className='list'>
                            <div className='list-item-box'>
                                <div className='list-item'>智能决策</div>
                                <div className='list-item'>业务流优化</div>
                                <div className='list-item'>知识生产管理</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fifth'>
                <div className='fifth-title-box'>
                    <p className='title'>企业动态</p>
                    <p className='desc'>专注AI Agents技术的创新与应用，致力于推动全球知识工作者的智能革命</p>
                </div>
                <Swiper
                    modules={[Autoplay, Mousewheel]}
                    slidesPerView={swiperCount}
                    spaceBetween={10}
                    loop={true} // 开启循环滚动
                    autoplay={{
                        delay: 1500, // 自动播放间隔时间，单位为毫秒
                        disableOnInteraction: true, // 用户操作后是否禁用自动播放
                        pauseOnMouseEnter: true
                    }}
                >
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486485&idx=1&sn=53031b945cc23383e6af84dc8d80149e&chksm=c1c5d496f6b25d8080c5dabce2f01f02e21f082f55e95289f8a9706afe29a3f81271f9313d98#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper9.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc' title='未来式智能联合创始人&COO邹阳：大模型的落地需要靠场景，而找到场景的前提是了解模型的能力边界'>未来式智能联合创始人&COO邹阳：大模型的落地需要靠场景，而找到场景的前提是了解模型的能力边界</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-07-23
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486485&idx=2&sn=3c65873e660f8dde7f8626b606a62a96&chksm=c1c5d496f6b25d80a3c585697960fd574ffdc18410386f56d43fe2aac6410e545c5f14d1b32d#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper10.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能出席北京市科协决策咨询沙龙，探讨人工智能大模型发展与落地</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-07-23
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486450&idx=1&sn=c75ae66a9b98d78a337dd3327ae672a0&chksm=c1c5d371f6b25a6796e77bac37504f5e75a124ba6c4def262055a876d84644d37dd86183af76#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper11.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc' title='未来式智能正式加入北京市通用人工智能产业创新伙伴计划，与中山大学合作案例获评大模型场景应用典型案例'>未来式智能正式加入北京市通用人工智能产业创新伙伴计划，与中山大学合作案例获评大模型场景应用典型案例</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-07-03
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486405&idx=1&sn=f623a87fed18ff74bf700169af3deb4c&chksm=c1c5d346f6b25a50c943137d54ae9df103958155c37d4f0b6e37c88321621f2ea9c72a024691#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper12.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能携手火山引擎，赋能企业数字化转型新范式</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-05-20
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486386&idx=1&sn=428d9c373ee3042a2cd0c6533b9306d8&chksm=c1c5d331f6b25a27d1b86c811a56eb88f6d9933ba94c30bae30488a9cb44d2283b8800d5de95#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper13.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>中国信通院正式发布智能体三项标准，未来式智能领编参与标准制定</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-04-30
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486375&idx=1&sn=7a3f1aa1f04ae6d6e1e4e0e731920940&chksm=c1c5d324f6b25a32096c2f4e270749ecbc3c1e727e6dc66d905c4495f86cd6c7a816f5080eca#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper14.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>受邀 2024 中关村论坛，未来式智能科技展现 AI 创新力量</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-04-29
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s?__biz=MzkyNTU2OTUxNg==&mid=2247486363&idx=1&sn=7c14da6b9bc12b3a6815eaf5735a90e4&chksm=c1c5d318f6b25a0e996db9a7b81efb4e70072dcd84eec6626953090dc9c8c295d97ce781e429#rd')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper15.png' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能科技亮相华为合作伙伴大会， AI Agents 大模型落地方案受关注</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-03-21
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    








                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/Xwh5CBJ3TgiNqlWMOR875g')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper8.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>海淀区政协与人工智能企业深度交流，未来式智能获高度关注</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-22
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/cURf8G_tiFMVRl0FiSt9HA')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper7.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>未来式智能与国网成都供电公司深度合作，全面推进大模型技术应用</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-07
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/lGhfEPAt1oXtYi9viVNcjQ')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper6.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>CEO杨劲松受邀出席AIGC发展高峰论坛，未来式智能火爆会场</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-02-02
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/uVIqAEQZjxXO-aocTUweWg')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper5.svg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p tooltip='未来式智能受邀参与行业会议，探讨汽车行业的AI创新之路' className='desc'>未来式智能受邀参与行业会议，探讨汽车行业的AI创新之路</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2024-01-05 
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://mp.weixin.qq.com/s/2ooM12oNLa_HIbaKksK0-g')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper2.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>专访AutoAgents.ai杨劲松：解锁人类意图的Agent型软件，将开启企业服务新纪元</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-26
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://baijiahao.baidu.com/s?id=1786213651332159441&wfr=spider&for=pc')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper1.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>36氪首发｜打造办公AI Agents，「AutoAgents」完成数千万元天使轮融资</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-25
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://m.huanqiu.com/article/4FligbfDQ62')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper4.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>中国信通院智能体标准研讨会召开 未来式CEO杨劲松出席参与标准制定</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-15
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='swiper-box' onClick={() => handleOpenPage('https://www.geekpark.net/news/328530')}>
                            <img className='swiper-img' src='https://autoagents.oss-cn-beijing.aliyuncs.com/static/official/images/swiper3.jpg' alt="" />
                            <div className='swiper-content'>
                                <p className='title'>企业动态</p>
                                <p className='desc'>甲子引力年终盛典 | 未来式智能 CEO 杨劲松受邀出席，探讨 AI Agents 未来趋势</p>
                                <p className='time'>
                                    <img src={IconTime} alt="" />
                                    2023-12-04
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Home